



















































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import moment from 'moment'
import confirmModal from './confirmModal.vue'
import { MeetingsController } from '../../../services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    confirmModal,
    FlexTooltip,
  },
})
export default class reservedTable extends Vue {
  // 接受父组件的值
  @Prop({ default: () => {} }) private readonly data!: any
  @Prop() private readonly loading!: boolean
  @Prop() private readonly align!: string

  private teachers: Array<any> = []
  private cancelReserveList: Array<any> = []
  private reserveList: Array<any> = []
  private columnStatus: any = {}
  private submitLoading: boolean = false
  private modalVisble: boolean = false
  private modalType: any = 'confirm'
  private modalData: Array<any> = []
  private canLeave: boolean = false

  private meetingDay: any = 0

  private get dates(): any {
    let columnIndex = 0
    let tmpDates = [] as any
    ;(this.data.dates || []).forEach(date => {
      let start = moment(date.startTime)
      let index = tmpDates.findIndex(item => {
        let startTime = moment(item.startTime)
        return startTime.isSame(start, 'day')
      })
      if (index < 0) {
        tmpDates.push(date)
      }
    })
    tmpDates = tmpDates.map((item, index) => {
      let startTime = moment(item.startTime)
      let dateColumns = [] as any
      ;((this.data.appointees[0] || []).schedules || []).forEach(element => {
        if (moment(element.start).isSame(startTime, 'day')) {
          let innerIndex = dateColumns.length
          dateColumns.push({
            start: moment(element.start),
            end: moment(element.end),
            index: columnIndex,
          })
          this.$set(this.columnStatus, columnIndex, false)
          columnIndex++
        }
      })
      return {
        key: index,
        label: startTime.format('YYYY[.]MM[.]DD'),
        dateColumns,
        time: startTime,
      }
    })
    this.getTeachers()
    return tmpDates.filter(item => item.dateColumns.length)
  }

  private get columns(): Array<Object> {
    let day = this.dates[this.meetingDay] || []
    let dynamicColumns = (day.dateColumns || []).map(column => {
      return {
        dataIndex: `dayColumn${column.index}`,
        title: `${column.start.format('HH:mm')}-${column.end.format('HH:mm')}`,
        key: `dayColumn${column.index}`,
        scopedSlots: { customRender: `dayColumn${column.index}` },
        align: this.align,
        columnIndex: column.index,
      }
    })
    return [
      {
        dataIndex: 'name',
        title: this.$t('common.teacher'),
        fixed: 'left',
        ellispsis: true,
        width: 150,
        scopedSlots: { customRender: 'commonTeacher' },
      },
      {
        dataIndex: 'relationInfo',
        title: this.$t('common.class'),
        fixed: 'left',
        ellispsis: true,
        width: 150,
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        dataIndex: 'classroom',
        title: this.$t('common.classroom'),
        fixed: 'left',
        ellispsis: true,
        width: 150,
        scopedSlots: { customRender: 'classroom' },
      },
      ...dynamicColumns,
    ]
  }

  private get currentStudent(): any {
    return this.$store.state.currentStudent || {}
  }

  private getTeachers(): any {
    this.teachers = (this.data.appointees || []).map(appointee => {
      let teacher = {
        appointeeId: appointee.appointeeId,
        name: appointee.name,
        displayName: appointee.displayName,
        relationInfo: appointee.relationInfo,
        classroom: appointee.classRoom,
      }

      let schedules = {
        isReserved: false,
      } as any
      appointee.schedules.forEach((schedule, index) => {
        // if(schedule.disabled)return
        this.$set(schedules, `dayColumn${index}`, { ...schedule })
        if (schedule.appointed && schedule.my) {
          schedules.isReserved = true
          this.columnStatus[index] = true
        }
      })
      return {
        ...teacher,
        ...schedules,
      }
    })
  }

  private reserve(appointment, rowIndex, columnIndex): void {
    this.teachers[rowIndex].isReserved = true
    this.columnStatus[columnIndex] = true
    this.$set(appointment, 'currentMy', true)
    let id = appointment.appointmentId
    let cancelIndex = this.cancelReserveList.findIndex(item => item.appointmentId === id)
    if (cancelIndex !== -1) {
      this.cancelReserveList.splice(cancelIndex, 1)
    } else {
      this.reserveList.push({
        appointmentId: id,
        appointeeId: this.teachers[rowIndex].appointeeId,
        name: this.teachers[rowIndex].displayName
          ? this.teachers[rowIndex].displayName
          : this.teachers[rowIndex].name,
        timeString: this.getTimeString(appointment.start, appointment.end),
        confirmString: `${this.teachers[rowIndex].name} ${this.getTimeString(
          appointment.start,
          appointment.end
        )}`,
      })
    }
  }

  private cancelReserve(appointment, rowIndex, columnIndex): void {
    let current = moment()
    let offDate = current.isAfter(appointment.start)
    if (offDate || !appointment.currentMy) return
    this.teachers[rowIndex].isReserved = false
    this.columnStatus[columnIndex] = false
    this.$set(appointment, 'currentMy', false)
    let id = appointment.appointmentId
    let reserveIndex = this.reserveList.findIndex(item => item.appointmentId === id)
    if (reserveIndex !== -1) {
      this.reserveList.splice(reserveIndex, 1)
    } else {
      this.cancelReserveList.push({
        appointmentId: id,
        appointeeId: this.teachers[rowIndex].appointeeId,
      })
    }
  }

  private calcReserved(appointment): boolean {
    let current = moment()
    let index = this.reserveList.findIndex(item => item.appointmentId === appointment.appointmentId)
    let startTime = moment(this.data.startTime)
    let noStart = current.isBefore(startTime)
    let cancelIndex = this.cancelReserveList.findIndex(
      item => item.appointmentId === appointment.appointmentId
    )
    return (index !== -1 || appointment.appointed) && cancelIndex === -1 && !noStart
  }

  private calcDisabled(appointment, rowIndex, columnIndex): boolean {
    let current = moment()
    let startTime = moment(this.data.startTime)
    let enterDate = moment(this.currentStudent.enterDate || 0)
    let offDate = current.isAfter(appointment.start)
    let noStart = current.isBefore(startTime) || moment(appointment.start).isBefore(enterDate)
    return (
      this.teachers[rowIndex].isReserved ||
      this.columnStatus[columnIndex] ||
      offDate ||
      appointment.disabled ||
      noStart
    )
  }

  private calcClass(appointment): boolean {
    let current = moment()
    let offDate = current.isAfter(appointment.start)
    return (!appointment.my && !offDate) || appointment.currentMy
  }

  private submitReserve(): void {
    if (this.reserveList.length) {
      this.modalType = 'confirm'
      this.modalData = this.reserveList
      this.modalVisble = true
    } else if (this.cancelReserveList.length) {
      this.submitRequest('leave')
    } else {
      this.canLeave = true
      this.cancel()
    }
  }

  private submitRequest(operation): void {
    let parent = this.$parent as any
    let condition = {
      meetingId: Number(parent.meetingId),
      studentId: Number(parent.studentId),
      appointments: this.reserveList,
      disappointments: this.cancelReserveList,
    }
    // displayName
    MeetingsController.makeAppointment(condition)
      .then(res => {
        this.canLeave = true
        if (operation === 'leave') {
          this.$message.success(this.$tc('parentsMeeting.updateSuccess'))
          this.$router.replace({ name: 'parentsMeeting', params: { viewType: 'reserved' } })
        } else {
          this.modalType = 'result'
          this.modalData = res.data.map(item => {
            return {
              result: item.result,
              name: item.teacher,
              timeString: this.getTimeString(item.startTime, item.endTime),
              confirmString: `${item.teacher} ${this.getTimeString(item.startTime, item.endTime)}`,
            }
          })
          this.modalVisble = true
        }
      })
      .catch(err => console.log(err))
      .finally(() => {})
  }

  private modalConfirm(operation): void {
    if (operation === 'leave') {
      this.canLeave = true
      this.$router.replace({ name: 'parentsMeeting', params: { viewType: 'reserved' } })
    } else {
      this.submitRequest(operation)
    }
  }

  private getTimeString(startTime, endTime): string {
    let day = moment(startTime).format('YYYY[.]MM[.]DD')
    let start = moment(startTime).format('HH:mm')
    let end = moment(endTime).format('HH:mm')
    return `${day} ${start}~${end}`
  }

  private cancel(): void {
    if (!this.reserveList.length && !this.cancelReserveList.length) this.canLeave = true
    this.$router.back()
  }

  private changeday(day): void {
    this.meetingDay = day
    this.$forceUpdate()
  }
}
