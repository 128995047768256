
































































import { Component, Vue, Model, Emit, Prop } from 'vue-property-decorator'
@Component
export default class conflictModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  // 接受父组件的值

  @Prop({ default: () => [] }) private data!: Array<any>
  @Prop() private readonly type!: string

  private loading: boolean = false

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        title: this.$t('CCAClass.conflictName'),
        scopedSlots: { customRender: 'name' },
      },
      {
        dataIndex: 'type',
        key: 'type',
        align: 'left',
        title: this.$t('CCAClass.conflictType'),
        scopedSlots: { customRender: 'type' },
      },
      {
        dataIndex: 'conflictDate',
        key: 'conflictDate',
        align: 'left',
        width: 300,
        title: this.$t('CCAClass.conflictDateList'),
        scopedSlots: { customRender: 'conflictDate' },
      },
      {
        dataIndex: 'conflictTime',
        key: 'conflictTime',
        align: 'left',
        title: this.$t('CCAClass.conflictTime'),
        scopedSlots: { customRender: 'conflictTime' },
      },
    ]
  }

  private get hasFail(): boolean {
    let failList = this.data.filter(item => !item.result)
    return failList.length !== 0 && this.type !== 'confirm'
  }

  private get isPc(): any {
    return this.$store.state.isPc
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('confirm')
  private confirm(operation): any {
    this.closeModal()
    return operation
  }
}
