























































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import moment from 'moment'
import confirmModal from './confirmModal.vue'
import { MeetingsController } from '../../../services/request.service'
import infiniteScroll from 'vue-infinite-scroll'
import { Empty } from 'ant-design-vue'

@Component({
  components: {
    confirmModal,
  },
  directives: {
    infiniteScroll,
  },
})
export default class ReservedList extends Vue {
  // 接受父组件的值
  @Prop({ default: () => {} }) private readonly data!: any
  @Prop() private readonly loading!: boolean
  @Prop() private readonly status!: string

  private teachers: any = []
  private dates: any = []
  private cancelReserveList: Array<any> = []
  private reserveList: Array<any> = []
  private columnStatus: any = {}
  private submitLoading: boolean = false
  private modalVisble: boolean = false
  private modalType: any = 'confirm'
  private modalData: Array<any> = []
  private canLeave: boolean = false
  private Vue = Vue
  private get locale(): any {
    return {
      emptyText: this.$t('tips.noTeacher'),
    }
  }

  private meetingData: any = []
  private busy: any = false

  @Watch('data', { immediate: true, deep: true })
  private ondataChange(): any {
    let columnIndex = 0
    let tmpDates = [] as any
    ;(this.data.dates || []).forEach(date => {
      let start = moment(date.startTime)
      let index = tmpDates.findIndex(item => {
        let startTime = moment(item.startTime)
        return startTime.isSame(start, 'day')
      })
      if (index < 0) {
        tmpDates.push(date)
      }
    })
    tmpDates = tmpDates.map((item, index) => {
      let startTime = moment(item.startTime)
      let dateColumns = [] as any
      ;((this.data.appointees[0] || []).schedules || []).forEach(element => {
        if (moment(element.start).isSame(startTime, 'day')) {
          let innerIndex = dateColumns.length
          dateColumns.push({
            start: moment(element.start),
            end: moment(element.end),
            index: columnIndex,
          })
          this.$set(this.columnStatus, columnIndex, false)
          columnIndex++
        }
      })
      return {
        key: index,
        label: startTime.format('YYYY[.]MM[.]DD'),
        dateColumns,
        time: startTime,
      }
    })
    this.dates = tmpDates.filter(item => item.dateColumns.length)
    this.getTeachers()
  }

  private getTeachers(): any {
    this.teachers = (this.data.appointees || []).map(appointee => {
      let teacher = {
        appointeeId: appointee.appointeeId,
        name: appointee.name,
        relationInfo: appointee.relationInfo,
        classRoom: appointee.classRoom,
      }

      let isReserved = false

      let periods = appointee.schedules.map((schedule, index) => {
        if (schedule.appointed && schedule.my) {
          isReserved = true
          this.columnStatus[index] = true
        }
        return {
          ...schedule,
          isReserved: schedule.appointed && schedule.my && schedule.status === 'ended',
          isOther: schedule.appointed && !schedule.my,
          index,
        }
      })
      return {
        ...teacher,
        hidden: isReserved,
        currentPeriod: undefined,
        periods,
      }
    })
    // this.$nextTick(() => {
    //   this.$forceUpdate()
    // })
  }

  private reserve(appointment, teacher): void {
    if (this.calcDisabled(appointment, teacher)) return
    teacher.isReserved = true
    appointment.isReserved = true
    appointment.my = true
    this.columnStatus[appointment.index] = true
    this.$set(appointment, 'currentMy', true)
    let id = appointment.appointmentId
    let cancelIndex = this.cancelReserveList.findIndex(item => item.appointmentId === id)
    if (cancelIndex !== -1) {
      this.cancelReserveList.splice(cancelIndex, 1)
    } else {
      this.reserveList.push({
        appointmentId: id,
        appointeeId: teacher.appointeeId,
        name: teacher.name,
        timeString: this.getTimeString(appointment.start, appointment.end),
        confirmString: `${teacher.name} ${this.getTimeString(appointment.start, appointment.end)}`,
      })
    }
  }

  private cancelReserve(appointment, teacher): void {
    let current = moment()
    let offDate = current.isAfter(appointment.start)
    if (offDate || !appointment.my) return
    teacher.isReserved = false
    appointment.isReserved = false
    appointment.my = false
    this.columnStatus[appointment.index] = false
    this.$set(appointment, 'currentMy', false)
    let id = appointment.appointmentId
    let reserveIndex = this.reserveList.findIndex(item => item.appointmentId === id)
    if (reserveIndex !== -1) {
      this.reserveList.splice(reserveIndex, 1)
    } else {
      this.cancelReserveList.push({
        appointmentId: id,
        appointeeId: teacher.appointeeId,
      })
    }
  }

  private calcDisabled(appointment, teacher): boolean {
    const columnIndex = appointment.index
    let current = moment()
    let startTime = moment(this.data.startTime)
    let offDate = current.isAfter(appointment.start)
    let noStart = current.isBefore(startTime)
    return (
      (teacher.isReserved ||
        this.columnStatus[columnIndex] ||
        offDate ||
        appointment.disabled ||
        appointment.isOther ||
        noStart) &&
      !appointment.currentMy
    )
  }

  private submitReserve(): void {
    if (this.reserveList.length) {
      this.modalType = 'confirm'
      this.modalData = this.reserveList
      this.modalVisble = true
    } else if (this.cancelReserveList.length) {
      this.submitRequest('leave')
    } else {
      this.canLeave = true
      this.cancel()
    }
  }

  private submitRequest(operation): void {
    let parent = this.$parent as any
    let condition = {
      meetingId: Number(parent.meetingId),
      studentId: Number(parent.studentId),
      appointments: this.reserveList,
      disappointments: this.cancelReserveList,
    }
    MeetingsController.makeAppointment(condition)
      .then(res => {
        this.canLeave = true
        if (operation === 'leave') {
          this.$message.success(this.$tc('parentsMeeting.updateSuccess'))
          this.$router.replace({ name: 'parentsMeeting', params: { viewType: 'reserved' } })
        } else {
          this.modalType = 'result'
          this.modalData = res.data.map(item => {
            return {
              result: item.result,
              name: item.teacher,
              timeString: this.getTimeString(item.startTime, item.endTime),
              confirmString: `${item.teacher} ${this.getTimeString(item.startTime, item.endTime)}`,
            }
          })
          this.modalVisble = true
        }
      })
      .catch(err => console.log(err))
      .finally(() => {})
  }

  private modalConfirm(operation): void {
    if (operation === 'leave') {
      this.canLeave = true
      this.$router.replace({ name: 'parentsMeeting', params: { viewType: 'reserved' } })
    } else {
      this.submitRequest(operation)
    }
  }

  private getTimeString(startTime, endTime): string {
    let day = moment(startTime).format('YYYY[.]MM[.]DD')
    let start = moment(startTime).format('HH:mm')
    let end = moment(endTime).format('HH:mm')
    return `${day} ${start}~${end}`
  }

  private cancel(): void {
    if (!this.reserveList.length && !this.cancelReserveList.length) this.canLeave = true
    this.$router.back()
  }

  private changeDay(item, day): void {
    this.$set(item, 'currentDay', day)
  }

  private handleInfiniteOnLoad(): void {}

  private getPeriod(item): any {
    return item.currentDay || item.currentDay === 0
      ? item.periods.filter(period =>
          moment(period.start).isSame(this.dates[item.currentDay]?.time, 'day')
        )
      : []
  }

  private getDayPeriods(): any {
    return []
  }

  // private mounted() {
  //   this.ratio = (window.innerWidth) / 375
  // }
}
