

















































































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import reservedTable from './ReservedTable.vue'
import reservedList from './ReservedList.vue'
import moment from 'moment'
import { MeetingsController } from '@/services/request.service'

Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
    reservedTable,
    reservedList,
  },
})
export default class meetingDetail extends Vue {
  private meetingInfo: any = {}
  private meetingId: any
  private studentId: any
  private startFlag: boolean = false
  private loading: boolean = false
  private get infoList(): Array<any> {
    let data = this.dealDetailData(this.meetingInfo)
    return [
      {
        key: 'meetingTime',
        label: this.$t('parentsMeeting.meetingTime'),
        value: this.isPc ? data.meetingTime : data.dates,
        icon: 'clock-circle',
      },
      {
        key: 'duration',
        label: this.$t('parentsMeeting.duration'),
        value: data.durationInMin,
        icon: 'hourglass',
      },
      {
        key: 'reserveTime',
        label: this.$t('parentsMeeting.reserveTime'),
        value: Vue.filter('doubleMoment')([data.startTime, data.endTime], 'YYYY.MM.DD HH:mm'),
        icon: 'check-circle',
      },
    ]
  }
  private get isPc(): any {
    return this.$store.state.isPc
  }

  private getMeetingInfoData(meetingId, studentId): void {
    this.meetingInfo = {}
    this.loading = true
    MeetingsController.getMeeting(meetingId, studentId)
      .then(res => {
        this.meetingInfo = res.data
        this.meetingInfo.appointees = this.meetingInfo.appointees.map(item => {
          item.schedules.sort((a, b) => a.start - b.start)
          return { ...item }
        })
        let startTime = moment(res.data.startTime)
        let current = moment()
        this.startFlag = startTime.isBefore(current)
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private dealDetailData(data): any {
    if (!Object.keys(data).length)
      return {
        durationInMin: '',
        reserveBeginTime: '',
        meetingTime: '',
      }
    let meetingTime = data.dates
      .map(item => {
        let { startTime, endTime } = item
        let day = moment(startTime).format('YYYY[.]MM[.]DD')
        let start = moment(startTime).format('HH:mm')
        let end = moment(endTime).format('HH:mm')
        return `${day} ${start} - ${end}`
      })
      .join('、')
    let reserveBeginTime = moment(data.startTime).format('YYYY[.]MM[.]DD HH:mm')
    return {
      ...data,
      meetingTime,
      durationInMin: data.durationInMin + 'mins',
      reserveBeginTime,
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    this.meetingId = to.query.meetingId
    this.studentId = to.query.studentId
    this.getMeetingInfoData(this.meetingId, this.studentId)
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if ((this.meetingInfo.appointees || []).length && !(this.$refs.MDRT as any).canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
}
