






































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { MeetingsController, MessageController } from '@/services/request.service'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { i18n } from '@/i18n/i18n'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { getGuideViewStatus, getIntroShow, toPage } from '@/utils/utils'
import infiniteScroll from 'vue-infinite-scroll'
import { createPagination } from '@/constant/constant'
import Guide from '@/components/Guide.vue'

@Component({
  components: {
    FlexTooltip,
    Guide,
  },
  directives: {
    infiniteScroll,
  },
})
export default class parentsMeeting extends Vue {
  private filter: any = {
    name: '',
  }
  private loading: boolean = false
  private guideVis: boolean = false
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private meetingData: Array<any> = []
  private viewType: any = 'reservable'
  private ratio: any = 1
  private busy: boolean = false
  private loadEnd: boolean = false

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private get isPc(): any {
    return this.$store.state.isPc
  }

  @Watch('studentId', { immediate: true })
  public onStudentChange(newVal): void {
    if (newVal && this.type) {
      // this.getData()

      // 可能会导致页面出现重复数据的问题
      this.loading = false

      this.loadEnd = false
      this.busy = false
      this.meetingData = []
      this.isPc ? this.getData() : this.fetchData(1)
    }
  }
  private get type(): any {
    return this.$route.params.viewType
  }
  private get viewList(): Array<any> {
    return [
      {
        key: 'reservable',
        label: this.$t('parentsMeeting.reservable'),
      },
      {
        key: 'reserved',
        label: this.$t('parentsMeeting.reserved'),
      },
    ]
  }
  private get columns(): Array<Object> {
    let columns = [
      {
        dataIndex: 'name',
        key: 'name',
        title: this.$t('parentsMeeting.meetingName'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
    ]
    let diffColumns =
      this.type === 'reservable'
        ? [
            {
              dataIndex: 'meetingTime',
              key: 'meetingTime',
              title: this.$t('parentsMeeting.meetingTime'),
              scopedSlots: { customRender: 'meetingTime' },
              ellipsis: true,
            },
            {
              key: 'reserveTime',
              title: this.$t('parentsMeeting.reserveTime'),
              ellipsis: true,
              scopedSlots: { customRender: 'reserveTime' },
            },
            {
              dataIndex: 'totalAvailableCount',
              key: 'teacherReservable',
              title: this.$t('parentsMeeting.teacherReservable'),
              ellipsis: true,
              scopedSlots: { customRender: 'teacherReservable' },
            },
            {
              dataIndex: 'status',
              title: this.$t('common.status'),
              scopedSlots: { customRender: 'status' },
            },
          ]
        : [
            {
              key: 'teacher',
              title: this.$t('parentsMeeting.teacher'),
              ellipsis: true,
              scopedSlots: { customRender: 'teacher' },
            },
            {
              dataIndex: 'meetingTime',
              key: 'meetingTime',
              title: this.$t('parentsMeeting.meetingTime'),
              ellipsis: true,
              scopedSlots: { customRender: 'meetingTime' },
            },
            {
              dataIndex: 'classRoom',
              key: 'classroom',
              title: this.$t('parentsMeeting.classroom'),
              ellipsis: true,
              scopedSlots: { customRender: 'classroom' },
            },
            {
              dataIndex: 'url',
              key: 'url',
              title: this.$t('parentsMeeting.onlineLink'),
              scopedSlots: { customRender: 'url' },
            },
            {
              dataIndex: 'status',
              key: 'status',
              title: this.$t('parentsMeeting.status'),
              scopedSlots: { customRender: 'status' },
            },
          ]
    columns = columns.concat(diffColumns as Array<any>)
    return [
      ...columns,
      {
        key: 'operations',
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }
  private get showChild(): boolean {
    return this.$route.name !== 'parentsMeeting'
  }

  private changeName = debounce(() => {
    this.getData()
  }, 500)

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    if (this.loading) return
    this.meetingData = []
    this.loading = true
    const type = this.type
    if (this.type === 'reservable') {
      MeetingsController.getMeetings(page.current, page.pageSize, this.studentId)
        .then(res => {
          if (type !== this.type) return
          this.meetingData = res.data.items.map(item => {
            let meetingTime = item.periods.map(element => {
              return this.getTimeString(element)
            })
            this.$set(item, 'meetingTime', meetingTime.join('；'))
            this.$set(
              item,
              'startTimeString',
              moment(item.startTime).format('YYYY[.]MM[.]DD HH:mm')
            )
            return item
          })
          this.pagination.total = res.data.totalItem
          this.pagination.current = page.current
          toPage(page, this.meetingData, page => {
            this.pagination.current = page
            this.refresh()
          })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      MeetingsController.getAppointed(this.studentId, page.current, page.pageSize)
        .then(res => {
          if (type !== this.type) return
          this.meetingData = res.data.items.map(item => {
            let meetingTime = this.getTimeString(item)
            this.$set(item, 'meetingTime', meetingTime)
            return item
          })
          this.pagination.total = res.data.totalItem
          this.pagination.current = page.current
          toPage(page, this.meetingData, page => {
            this.pagination.current = page
            this.refresh()
          })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  private viewDetails(meetingId): void {
    this.$router
      .push({
        name: 'parentsMeetingDetail',
        query: { meetingId, studentId: this.studentId as any },
      })
      .catch(err => {})
  }

  private getTimeString(timeItem): string {
    let day = moment(timeItem.startTime).format('YYYY[.]MM[.]DD')
    let start = moment(timeItem.startTime).format('HH:mm')
    let end = moment(timeItem.endTime).format('HH:mm')
    return `${day} ${start}~${end}`
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.getData(pagination)
  }

  private cancelReseved(appointeeId): void {
    if (this.isPc) {
      this.cancelRequest(appointeeId)
    } else {
      this.$confirm({
        title: this.$t('parentsMeeting.cancelConfirm') as string,
        onOk: () => {
          this.cancelRequest(appointeeId)
        },
        onCancel: () => {
          return
        },
      })
    }
  }

  private cancelRequest(appointeeId): void {
    MeetingsController.disappoint(appointeeId, this.studentId)
      .then(res => {
        this.$message.success(this.$tc('parentsMeeting.cancelReservedSuccess'))
      })
      .catch(err => console.log(err))
      .finally(() => this.refresh())
  }

  private reserveDiabled(item): boolean {
    let current = moment()
    if (!item.startTime) {
      return false
    }
    let startTime = moment(item.startTime)
    return current.isBefore(startTime)
  }

  private cancelDisabled(item): boolean {
    let current = moment()
    if (!item.startTime) {
      return false
    }
    let startTime = moment(item.startTime)
    return current.isAfter(startTime) || item.status === 'ended'
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'parentsMeeting') {
      if (!to.params.viewType) {
        this.$router
          .push({ name: 'parentsMeeting', params: { viewType: 'reservable' } })
          .catch(err => {})
      } else {
        if (to.params.viewType !== 'reservable' && to.params.viewType !== 'reserved') {
          this.$router.push({ name: 'parentsMeeting', params: { viewType: 'reservable' } })
        }
      }
    }
    if (this.studentId && this.type) {
      // this.getData()
      this.meetingData = []
      this.loadEnd = false
      this.busy = false
      this.isPc ? this.getData() : this.fetchData(1)
    }
  }

  private changeType(val): void {
    this.loading = false
    this.$router
      .push({ name: 'parentsMeeting', params: { viewType: val.target.value } })
      .catch(err => {})
  }

  private refresh(): void {
    this.loadEnd = false
    this.busy = false
    this.meetingData = []
    this.isPc ? this.getData() : this.fetchData(1)
  }

  private mounted() {
    this.ratio = (window.innerHeight - 104) / (812 - 104)
    let status = getGuideViewStatus()
    const introShow = getIntroShow() || {}
    if (!status.bookPC && introShow.pc) {
      this.guideVis = true
    }
  }

  private fetchData(current) {
    if (this.loading) return
    this.loading = true
    const type = this.type
    if (this.type === 'reservable') {
      MeetingsController.getMeetings(current, 10, this.studentId)
        .then(res => {
          if (type !== this.type) return
          this.meetingData = this.meetingData.concat(
            res.data.items.map(item => {
              let meetingTime = item.periods.map(element => {
                return this.getTimeString(element)
              })
              this.$set(item, 'meetingTime', meetingTime.join('；'))
              this.$set(
                item,
                'startTimeString',
                moment(item.startTime).format('YYYY[.]MM[.]DD HH:mm')
              )
              return item
            })
          )
          this.pagination.total = res.data.totalItem
          if (this.pagination.total === this.meetingData.length) {
            this.busy = true
          }
          this.loading = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      MeetingsController.getAppointed(this.studentId, current, 10)
        .then(res => {
          if (type !== this.type) return
          this.meetingData = this.meetingData.concat(
            res.data.items.map(item => {
              let meetingTime = this.getTimeString(item)
              this.$set(item, 'meetingTime', meetingTime)
              return item
            })
          )
          this.pagination.current = res.data.pageCurrent
          this.pagination.total = res.data.totalItem
          if (this.pagination.total === this.meetingData.length) {
            this.busy = true
          }
          this.loading = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  private handleInfiniteOnLoad() {
    if (this.loading || this.loadEnd) return
    if (this.busy) {
      this.$message.warning(this.$tc('common.loadEnd'))
      this.loadEnd = true
      this.loading = false
      return
    }
    this.fetchData(++this.pagination.current)
  }

  private openLink(link): void {
    if (link.indexOf('http') !== -1) {
      window.open(link, '_blank')
    } else {
      window.open(`http://${link}`, '_blank')
    }
  }
}
